import { AppContext } from "components/AppProvider";
import { useAuth } from "components/AuthProvider";
import Button from "components/buttons/Button";
import TextField from "components/formFields/Text";
import Link from "components/Link";
import {
  pendingOperationKeys,
  usePendingOperationsContext,
} from "components/pendingOperations/Provider";
import { Form, Formik } from "formik";
import useGoogleRecaptcha from "hooks/useGoogleRecaptcha";
import useQuery from "hooks/useQuery";
import {
  errorToast,
  Heading,
  Text,
  usePublicPagePopupsContext,
} from "iparque-components";
import DriversDataSource from "lib/clients/iParque/dataSources/driversDataSource";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import {
  createSocial as createSocialRequest,
  loginSocial as loginSocialRequest,
} from "requests/driver";
import styled from "styled-components";
import {
  checkDefaultEntity,
  getContractById,
  isGeneralDataProtectionRegulationAccepted,
} from "utils/auth";
import { breakpoints } from "utils/breakpoints";
import { userContractsMapping } from "utils/contracts";
import { formatDateTime } from "utils/dateTime";
import {
  DRIVER_EMAIL_PASSWORD_INVALID,
  DRIVER_NOT_ACTIVATED,
  hasErrorCode,
  PASSWORD_REQUIREMENTS_NOT_MET,
  TOO_MANY_UNSUCCESSFUL_ATTEMPTS,
} from "utils/error";
import { getButtonSize, hasObjectProperty } from "utils/general";
import { gaCategory, gaEvents } from "utils/googleAnalytics";
import { routesMapping } from "utils/routes";
import { warningMessage } from "utils/userMessages";
import * as Yup from "yup";
import { settingsTabs } from "../../private/settings/controller";
import FacebookLoginButton from "../components/FacebookLoginButton";
import LeftSideContainer from "../components/PageSplittedTemplate/LeftSideContainer";
import PageSplittedTemplate from "../components/PageSplittedTemplate/Template";
import RecaptchaInfo from "../components/RecaptchaInfo";
import GeneralDataProtectionPopup from "./GeneralDataProtectionPopup";
import InactiveAccountPopup from "./InactiveAccountPopup";
import PasswordRequirementsErrorPopup from "./PasswordRequirementsErrorPopup";
import PasswordRequirementsWarningPopup from "./PasswordRequirementsWarningPopup";

const PasswordRecoveryWrapper = styled.div`
  margin-top: 15px;
`;

const ButtonsContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
`;

const CreateAccountContainer = styled.div`
  margin-top: 30px;

  @media (max-width: ${breakpoints.md}) {
    line-height: 20px;
  }
`;

const InlineContainer = styled.div`
  display: inline;
`;

const driversDataSource = new DriversDataSource();
let loginResponse = {};
let isCreateSocialCalled = false;

const Login = () => {
  const { breakpoint, setIsLoading } = useContext(AppContext);
  const query = useQuery();
  const history = useHistory();
  const { executeGoogleRecaptcha, isGoogleRecaptchaLoaded } = useGoogleRecaptcha();
  const location = useLocation();
  const { setSession, isAuthenticated, user } = useAuth();
  const { addPendingOperation } = usePendingOperationsContext();
  const { dismissPopups, displayPopups } = usePublicPagePopupsContext();
  const formRef = useRef(null);

  const { token: permitHolderToken, entityToken } = query;
  const associatePermitHolder = permitHolderToken && entityToken;

  const newLocation = (() => {
    if (location?.state?.from) {
      return location?.state?.from;
    }

    return routesMapping.backofficeParkings;
  })();

  const [
    displayPasswordRequirementsWarning,
    setDisplayPasswordRequirementsWarning,
  ] = useState(false);

  const [
    displayPasswordRequirementsError,
    setDisplayPasswordRequirementsError,
  ] = useState(false);

  const [
    displayGeneralDataProtectionRegulationPopup,
    setDisplayGeneralDataProtectionRegulationPopup,
  ] = useState(false);

  const { t } = useTranslation();

  const hideGeneralDataProtectionPopup = useCallback(() => {
    setDisplayGeneralDataProtectionRegulationPopup(false);
  }, []);

  const proceedLoginGeneralDataProtectionRegulation = useCallback(
    (callback) => setSession(loginResponse, callback),
    [setSession]
  );

  const proceedLogin = useCallback(() => setSession(loginResponse), [setSession]);

  const [displayInactiveAccountPopup, setDisplayInactiveAccountPopup] = useState(false);

  useEffect(() => {
    if (associatePermitHolder) {
      addPendingOperation(
        pendingOperationKeys.associatePermitHolder,
        routesMapping.backofficePermitHolders,
        entityToken,
        { token: permitHolderToken }
      );
    }

    return () => (!isAuthenticated ? displayPopups() : null);
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    if (!checkDefaultEntity(user)) {
      history.push(routesMapping.backofficeCities);
    } else if (!isGeneralDataProtectionRegulationAccepted()) {
      history.push(
        `${routesMapping.backofficeDefinitions}?activeTabId=${settingsTabs.account}`
      );
    } else {
      history.push(newLocation);
    }
  }, [isAuthenticated]);

  const hasGeneralDataProtectionRegulationContractAccepted = useCallback((loginData) => {
    const generalDataProtectionRegulationContract = getContractById(
      userContractsMapping.generalDataProtectionRegulation,
      loginData.contracts
    );

    return isGeneralDataProtectionRegulationAccepted(
      generalDataProtectionRegulationContract
    );
  }, []);

  const login = useCallback(
    async (loginData) => {
      setIsLoading(true);
      dismissPopups();

      const recaptchaToken = await executeGoogleRecaptcha();

      if (!recaptchaToken) {
        setIsLoading(false);
        warningMessage(t("7858") /* Por favor, tenta novamente. */);
        return;
      }

      try {
        const response = await driversDataSource.login({
          recaptchaToken,
          ...loginData,
        });

        if (
          hasObjectProperty(response, "passwordMeetsRequirements") &&
          hasObjectProperty(response, "passwordRequirementsDeadline") &&
          !response.passwordMeetsRequirements
        ) {
          loginResponse = response;
          setDisplayPasswordRequirementsWarning(true);
          return;
        }

        if (!hasGeneralDataProtectionRegulationContractAccepted(response)) {
          loginResponse = response;
          setDisplayGeneralDataProtectionRegulationPopup(true);
          return;
        }

        setSession(response);

        ReactGA.event({
          category: gaCategory,
          action: gaEvents.loginSuccessfully,
        });
      } catch (error) {
        if (hasErrorCode(error, PASSWORD_REQUIREMENTS_NOT_MET)) {
          setDisplayPasswordRequirementsError(true);
          return;
        }

        if (hasErrorCode(error, DRIVER_EMAIL_PASSWORD_INVALID)) {
          errorToast(
            t("7847") /* Erro! */,
            t("10857") /* Os teus dados de autenticação introduzidos estão incorretos. */
          );
          return;
        }

        if (hasErrorCode(error, TOO_MANY_UNSUCCESSFUL_ATTEMPTS)) {
          errorToast(
            t("7847") /* Erro! */,
            t(
              "10858"
            ) /* A tua conta encontra-se, de momento, bloqueada devido a várias tentativas falhadas de autenticação. Para mais informações, por favor, contacta os nossos serviços de apoio. */
          );
          return;
        }

        if (hasErrorCode(error, DRIVER_NOT_ACTIVATED)) {
          setDisplayInactiveAccountPopup(true);
          return;
        }

        errorToast(
          t("7847") /* Erro! */,
          t("7859") /* Ocorreu um erro ao realizar o login */
        );
      } finally {
        setIsLoading(false);
      }
    },
    [
      executeGoogleRecaptcha,
      hasGeneralDataProtectionRegulationContractAccepted,
      setIsLoading,
      setSession,
      t,
    ]
  );

  const loginSocial = useCallback(
    async (socialNetwork, params) => {
      setIsLoading(true);
      dismissPopups();

      if (!params.email) {
        setIsLoading(false);
        return;
      }

      const loginData = await loginSocialRequest(socialNetwork, params);

      if (!loginData) {
        setIsLoading(false);
        return;
      }

      const { response, hasAccount } = loginData;

      if (!hasAccount && !isCreateSocialCalled) {
        const registryData = await createSocialRequest(socialNetwork, params);
        isCreateSocialCalled = true; // Prevent infinite loop

        if (registryData) {
          loginSocial(socialNetwork, params);
        }

        return;
      }

      setIsLoading(false);

      if (!hasGeneralDataProtectionRegulationContractAccepted(response)) {
        loginResponse = { ...response, loginWithExternalApp: true };
        setDisplayGeneralDataProtectionRegulationPopup(true);
        return;
      }

      setSession({ ...response, loginWithExternalApp: true });

      ReactGA.event({
        category: gaCategory,
        action: gaEvents.loginSuccessfully,
      });
    },
    [hasGeneralDataProtectionRegulationContractAccepted, setSession]
  );

  return (
    <>
      <PasswordRequirementsWarningPopup
        visible={displayPasswordRequirementsWarning}
        onClose={() => setDisplayPasswordRequirementsWarning(false)}
        onContinue={proceedLogin}
        requirementsDeadline={formatDateTime(loginResponse.passwordRequirementsDeadline)}
      />
      <PasswordRequirementsErrorPopup
        visible={displayPasswordRequirementsError}
        onClose={() => setDisplayPasswordRequirementsError(false)}
      />
      <GeneralDataProtectionPopup
        visible={displayGeneralDataProtectionRegulationPopup}
        onClose={hideGeneralDataProtectionPopup}
        authHash={loginResponse?.authHash}
        token={loginResponse.token || ""}
        loginCallback={proceedLoginGeneralDataProtectionRegulation}
      />
      <InactiveAccountPopup
        email={formRef.current?.values?.email}
        visible={displayInactiveAccountPopup}
        onClose={() => setDisplayInactiveAccountPopup(false)}
      />
      <PageSplittedTemplate title={t("7861") /* Bem-vindo ao */}>
        <LeftSideContainer>
          {associatePermitHolder && (
            <Heading color="secondary" variant="h7" className="mt-20 mb-20">
              {
                t(
                  "10546"
                ) /* Efetua o login para que o teu dístico seja associado à tua conta. */
              }
            </Heading>
          )}
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={Yup.object({
              email: Yup.string()
                .required(t("10271") /* O campo é obrigatório */)
                .email(t("7894") /* O campo deverá ser válido */),
              password: Yup.string().required(t("10271") /* O campo é obrigatório */),
            })}
            onSubmit={login}
            innerRef={formRef}
          >
            <Form>
              <TextField
                type="email"
                id="email-field"
                name="email"
                label={t("178") /* Email */}
              />
              <TextField
                id="password-field"
                type="password"
                name="password"
                label={t("3550") /* Senha */}
              />
              <PasswordRecoveryWrapper>
                <Text color="secondary" variant="body6">
                  <Link
                    underline="always"
                    to={routesMapping.passwordRecovery}
                    aria-label={t("7865") /* Recuperar password */}
                  >
                    {t("7866") /* Não te lembras da senha? */}
                  </Link>
                </Text>
              </PasswordRecoveryWrapper>
              <ButtonsContainer>
                <Button
                  type="submit"
                  disabled={!isGoogleRecaptchaLoaded}
                  size={getButtonSize(breakpoint)}
                >
                  {t("61") /* Entrar */}
                </Button>
                <FacebookLoginButton
                  size={getButtonSize(breakpoint)}
                  className="mt-20"
                  loginCallback={loginSocial}
                />
              </ButtonsContainer>
              <RecaptchaInfo />
            </Form>
          </Formik>
        </LeftSideContainer>
        <CreateAccountContainer>
          <InlineContainer>
            <Heading color="secondary" elementType="h4" variant="h7">
              {t("7868") /* Ainda não estacionas com */}
              &nbsp;
            </Heading>
            <Heading color="secondary" elementType="h4" italic variant="h7">
              {t("7869") /* o driver? */}
            </Heading>
          </InlineContainer>
          <br />
          <InlineContainer>
            <Heading color="secondary" variant="h8">
              {t("7870") /* É tão fácil como */}
              &nbsp;
            </Heading>
            <Heading color="quaternary" variant="h7">
              <Link to={routesMapping.registry} underline="always">
                {t("7871") /* Criar uma conta! */}
              </Link>
            </Heading>
          </InlineContainer>
        </CreateAccountContainer>
      </PageSplittedTemplate>
    </>
  );
};

export default Login;
