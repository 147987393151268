import i18n from "i18next";
import DriversDataSource from "lib/clients/iParque/dataSources/driversDataSource";
import { errorMessage, successMessage, warningMessage } from "utils/userMessages";
import { getPaymentMethodIdToServer } from "utils/payment";
import PaymentsDataSource from "lib/clients/iParque/dataSources/paymentsDataSource";
import {
  hasErrorCode,
  VISA_REFERENCE_ALREADY_PROCESSED,
  VISA_REFERENCE_WAS_PREVIOUSLY_USED,
} from "utils/error";

const driversDataSource = new DriversDataSource();
const paymentsDataSource = new PaymentsDataSource();

export const generateBalancePayment = async (entityId, authHash, params) => {
  try {
    const response = await driversDataSource.generateBalancePayment(entityId, authHash, {
      ...params,
      paymentTypeId: getPaymentMethodIdToServer(params.paymentMethodId),
    });

    return response;
  } catch (error) {
    errorMessage(
      error,
      i18n.t("9355") /* Ocorreu um erro ao gerar os dados do carregamento de saldo */
    );

    return null;
  }
};

export const finalizeNoticePayment = async (token) => {
  try {
    await paymentsDataSource.creditCardCapture(token);

    successMessage(i18n.t("9357") /* Carregamento de saldo realizado com sucesso */);

    return true;
  } catch (error) {
    if (hasErrorCode(error, VISA_REFERENCE_ALREADY_PROCESSED)) {
      successMessage(i18n.t("9357") /* Carregamento de saldo realizado com sucesso */);

      return true;
    }

    if (hasErrorCode(error, VISA_REFERENCE_WAS_PREVIOUSLY_USED)) {
      warningMessage(
        i18n.t("9359") /* O pagamento já foi processado anteriormente */,
        null,
        {
          autoClose: false,
        }
      );

      return false;
    }

    errorMessage(
      error,
      i18n.t("9358") /* Ocorreu um erro ao finalizar o carregamento de saldo */
    );

    return false;
  }
};
