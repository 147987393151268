import i18n from "i18next";
import PaymentsDataSource from "../lib/clients/iParque/dataSources/paymentsDataSource";
import { errorMessage } from "../utils/userMessages";

const paymentsDataSource = new PaymentsDataSource();

export const createCreditCardPayment = async (visaReference, params) => {
  try {
    const paymentData = await paymentsDataSource.creditCardCharge(visaReference, params);

    return paymentData;
  } catch (error) {
    errorMessage(
      error,
      i18n.t("7848") /* Ocorreu um erro ao processar a referência de pagamento. */
    );

    return null;
  }
};

export const getPaymentMethods = async (entityId, transactionTypeId, params, { t }) => {
  try {
    const response = await paymentsDataSource.getPaymentMethods(
      entityId,
      transactionTypeId,
      { noLimit: true },
      true
    );

    return {
      items: response.items,
      totalItems: response.totalItems,
    };
  } catch (error) {
    errorMessage(error, t("9578") /* Ocorreu um erro ao obter os métodos de pagamento */);

    return { hasError: true };
  }
};
