import IParqueClient from "../iParqueClient";

// Instantiate globally once
const iParqueClient = new IParqueClient();

export default class AbstractDataSource {
  constructor() {
    this.iParqueClient = iParqueClient;
  }
}
