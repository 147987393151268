import { useCallback, useEffect, useState } from "react";
import { getQueryParam, queryKeys, removeQueryParam } from "utils/routes";
import { warningToast, successToast, errorToast } from "iparque-components";
import { useTranslation } from "react-i18next";
import PaymentsDataSource from "lib/clients/iParque/dataSources/paymentsDataSource";
import { useAppContext } from "components/AppProvider";
import { hasErrorCode, VISA_REFERENCE_ALREADY_PROCESSED } from "utils/error";

const paymentsDataSource = new PaymentsDataSource();

const useNoticePayment = () => {
  const noticeCreditCardPaymentCode = getQueryParam(
    queryKeys.noticeCreditCardPaymentCode
  );
  const [displayPaymentBox, setDisplayPaymentBox] = useState(
    !!noticeCreditCardPaymentCode
  );
  const [noticeCode, setNoticeCode] = useState(noticeCreditCardPaymentCode ?? "");
  const [isPaid, setIsPaid] = useState(undefined);
  const [isMinimized, setIsMinimized] = useState(false);
  const { t } = useTranslation();
  const { setIsLoading } = useAppContext();

  const finalizeNoticePayment = useCallback(
    (token) => {
      paymentsDataSource
        .creditCardCapture(token)
        .then(() => {
          successToast(
            t("7854") /* Sucesso! */,
            t("7855") /* A infração foi paga com sucesso. */,
            {
              autoClose: false,
            }
          );

          setIsPaid(true);
          setDisplayPaymentBox(false);
        })
        .catch((error) => {
          if (hasErrorCode(error, VISA_REFERENCE_ALREADY_PROCESSED)) {
            successToast(
              t("7854") /* Sucesso! */,
              t("7855") /* A infração foi paga com sucesso. */,
              {
                autoClose: false,
              }
            );

            setIsPaid(true);
            setDisplayPaymentBox(false);
            return;
          }

          errorToast(
            t("7847") /* Erro! */,
            t("7856") /* Não foi possível pagar a infração. */,
            {
              autoClose: false,
            }
          );

          setIsPaid(false);
        })
        .finally(() => {
          setIsLoading(false);
          removeQueryParam(queryKeys.noticePaymentSuccess);
          removeQueryParam(queryKeys.token);
        });
    },
    [t]
  );

  const creditCardCharge = useCallback((visaReference) => {
    removeQueryParam(queryKeys.noticeCreditCardPaymentCode);

    paymentsDataSource
      .creditCardCharge(visaReference)
      .then((data) => window.open(data.url, "_self"))
      .catch(() =>
        errorToast(
          t("7847") /* Erro! */,
          t("7848") /* Ocorreu um erro ao processar a referência de pagamento. */
        )
      );
  }, []);

  useEffect(() => {
    const noticePaymentSuccess = getQueryParam(queryKeys.noticePaymentSuccess);
    const noticePaymentToken = getQueryParam(queryKeys.token);

    if (noticePaymentSuccess === "false") {
      setIsPaid(false);
      warningToast(
        t("7852") /* Alerta! */,
        t("7853") /* O pagamento da infração foi cancelado */,
        {
          autoClose: false,
        }
      );

      removeQueryParam(queryKeys.noticePaymentSuccess);

      return;
    }

    if (noticePaymentSuccess === "true" && noticePaymentToken) {
      finalizeNoticePayment(noticePaymentToken, { setIsLoading, t });
    }
  }, [t, setIsLoading, finalizeNoticePayment]);

  const resetState = useCallback(() => {
    setIsPaid(undefined);
  }, []);

  const toggleMinimize = useCallback(
    (event) => {
      event.stopPropagation();

      setIsMinimized(!isMinimized);
    },
    [isMinimized]
  );

  return {
    displayPaymentBox,
    setDisplayPaymentBox,
    creditCardCharge,
    noticeCode,
    setNoticeCode,
    isPaid,
    resetState,
    isMinimized,
    toggleMinimize,
  };
};

export default useNoticePayment;
