import SelectField from "components/formFields/Select";
import TextField from "components/formFields/Text";
import { usePermitHoldersContext } from "components/permitHolders/Creation/Provider";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { breakpoints } from "utils/breakpoints";
import {
  partyTypesWithCompanyFields,
  partyTypes as clientPartyTypes,
} from "utils/clients";
import { PORTUGAL } from "utils/countries";
import { FieldContainer } from "../../../FormGrid";
import useFormOptions from "./useFormOptions";

const PermitHoldersFormBody = ({ entityId, values, readOnly }) => {
  const { t } = useTranslation();
  const { countries, partyTypes, districts, streets, localities } = useFormOptions(
    entityId
  );
  const { selectedPermitType } = usePermitHoldersContext();
  const formik = useFormikContext();

  const shouldRegiterAddressStreet =
    selectedPermitType.permitType.shouldRegisterAddressStreet;

  let shouldHidePartyType = false;

  if (
    selectedPermitType.permitType.designation.toLowerCase().includes("residente") ||
    selectedPermitType.permitType.designation.toLowerCase().includes("morador") ||
    selectedPermitType.permitType.designation.toLowerCase().includes("empresa")
  ) {
    shouldHidePartyType = true;
  }

  useEffect(() => {
    if (
      selectedPermitType.permitType.designation.toLowerCase().includes("residente") ||
      selectedPermitType.permitType.designation.toLowerCase().includes("morador")
    ) {
      formik.setFieldValue("partyTypeId", clientPartyTypes.person);
    } else if (
      selectedPermitType.permitType.designation.toLowerCase().includes("empresa")
    ) {
      formik.setFieldValue("partyTypeId", clientPartyTypes.company);
    }

    const locality = localities.find((l) => l.isDefault);

    if (!locality) {
      return;
    }

    formik.setFieldValue("localityId", locality.value);
  }, [localities]);

  return (
    <>
      <FieldContainer fullWidthOnSmallDevices>
        {!shouldHidePartyType && (
          <SelectField
            className="form-field"
            name="partyTypeId"
            options={partyTypes}
            label={t("3659") /* Tipo */}
            readOnly={readOnly}
          />
        )}
      </FieldContainer>
      <FieldContainer fullWidth>
        <TextField
          className="form-field"
          name="name"
          label={t("2038") /* Nome completo */}
          readOnly={readOnly}
        />
      </FieldContainer>
      <FieldContainer>
        <SelectField
          className="form-field"
          name="countryId"
          options={countries}
          label={t("2721") /* País */}
          readOnly={readOnly}
        />
      </FieldContainer>
      <FieldContainer>
        <TextField
          className="form-field"
          name="taxpayerIdentificationNumber"
          label={t("4198") /* NIF */}
          readOnly={readOnly}
          type={values.countryId === PORTUGAL ? "number" : "text"}
          displayNativeControls={false}
        />
      </FieldContainer>
      {partyTypesWithCompanyFields.includes(values.partyTypeId) && (
        <>
          <FieldContainer fullWidthOnSmallDevices>
            <TextField
              className="form-field"
              name="commercialName"
              label={t("9375") /* Designação comercial */}
              readOnly={readOnly}
            />
          </FieldContainer>
          <FieldContainer fullWidthOnSmallDevices>
            <TextField
              className="form-field"
              name="economicActivityCode"
              label={t("6395") /* CAE */}
              readOnly={readOnly}
              type="number"
            />
          </FieldContainer>
        </>
      )}
      <FieldContainer fullWidth>
        {!shouldRegiterAddressStreet && (
          <TextField
            className="form-field"
            name="address"
            label={t("2054") /* Rua */}
            readOnly={readOnly}
          />
        )}
        {shouldRegiterAddressStreet && (
          <SelectField
            className="form-field"
            name="addressStreetId"
            options={streets}
            label={t("2054") /* Rua */}
            readOnly={readOnly}
          />
        )}
      </FieldContainer>
      <AddressParts>
        <TextField
          className="form-field"
          name="block"
          label={t("1086") /* Bloco */}
          readOnly={readOnly}
        />
      </AddressParts>
      <AddressParts>
        <TextField
          className="form-field"
          name="floor"
          label={t("10631") /* Andar */}
          readOnly={readOnly}
        />
      </AddressParts>
      <AddressParts>
        <TextField
          className="form-field"
          name="doorNumber"
          label={t("1861") /* Porta */}
          readOnly={readOnly}
        />
      </AddressParts>
      <AddressParts>
        <TextField
          className="form-field"
          name="zipCode"
          label={t("4830") /* Código postal */}
          readOnly={readOnly}
        />
      </AddressParts>
      <FieldContainer>
        {!shouldRegiterAddressStreet && (
          <TextField
            className="form-field"
            name="locality"
            label={t("483") /* Localidade */}
            readOnly={readOnly}
          />
        )}
        {shouldRegiterAddressStreet && (
          <SelectField
            className="form-field"
            name="localityId"
            options={localities}
            label={t("483") /* Localidade */}
            readOnly={readOnly}
          />
        )}
      </FieldContainer>
      <FieldContainer>
        <SelectField
          className="form-field"
          name="districtId"
          options={districts}
          label={t("494") /* Distrito */}
          readOnly={readOnly}
        />
      </FieldContainer>
      <FieldContainer fullWidth>
        <TextField
          className="form-field"
          name="email"
          label={t("178") /* Email */}
          readOnly={readOnly}
        />
      </FieldContainer>
      <FieldContainer>
        <TextField
          className="form-field"
          name="phone"
          label={t("2050") /* Telefone */}
          readOnly={readOnly}
          type="tel"
          displayNativeControls={false}
        />
      </FieldContainer>
      <FieldContainer>
        <TextField
          className="form-field"
          name="mobilePhone"
          label={t("398") /* Telemóvel */}
          readOnly={readOnly}
          type="tel"
          displayNativeControls={false}
        />
      </FieldContainer>
    </>
  );
};

export default PermitHoldersFormBody;

PermitHoldersFormBody.propTypes = {
  entityId: PropTypes.number.isRequired,
  readOnly: PropTypes.bool.isRequired,
  values: PropTypes.shape({
    partyTypeId: PropTypes.number,
    countryId: PropTypes.number,
  }).isRequired,
};

const AddressParts = styled.div`
  grid-column-end: span 6;

  @media (min-width: ${breakpoints.xl}), (max-width: ${breakpoints.lg}) {
    grid-column-end: span 3;
  }

  @media (max-width: ${breakpoints.md}) {
    grid-column-end: span 6;
  }
`;
