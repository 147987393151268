import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { warningMessage } from "utils/userMessages";
import GeneralCreditCardPaymentBox from "domain/private/components/paymentMethods/CreditCardPaymentBox";
import { createCreditCardPayment } from "requests/payments";
import { useAuth } from "components/AuthProvider";
import { finalizeNoticePayment, generateBalancePayment } from "../controller";
import useAccountMovements from "../../hooks/useAccountMovements";
import { paymentBoxPropTypes } from "./propTypes";

const CreditCardPaymentBox = ({ initialFlowCallback, paymentMethod }) => {
  const { t } = useTranslation();
  const { defaultEntityId, authHash, userId } = useAuth();
  const [details, setDetails] = useState({});
  const { backToList } = useAccountMovements();

  const verifyPendingPayment = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const success = urlParams.get("success");
    const paymentToken = urlParams.get("token");

    if (!success && !paymentToken) {
      return;
    }

    if (success === "false") {
      warningMessage(t("7853") /* O pagamento da infração foi cancelado */, null, {
        autoClose: false,
      });

      return;
    }

    if (!paymentToken) {
      warningMessage(t("3402") /* Ocorreu um erro */, null, {
        autoClose: false,
      });

      return;
    }

    setDetails({
      isLoading: true,
    });

    const result = await finalizeNoticePayment(paymentToken);

    setDetails({
      isLoading: false,
    });

    if (result) {
      backToList();
    }
  };

  useEffect(() => {
    verifyPendingPayment();
  }, []);

  const onClick = () => {
    const promise = new Promise((resolve) => {
      (async () => {
        const amount = await initialFlowCallback(paymentMethod);

        if (!amount) {
          resolve(false);
          return;
        }

        resolve(true);

        setDetails({
          isLoading: true,
        });

        const paymentData = await generateBalancePayment(defaultEntityId, authHash, {
          amount,
          paymentMethodId: paymentMethod.id,
        });

        if (!paymentData) {
          setDetails({
            isLoading: false,
          });
        }

        const creditCardData = await createCreditCardPayment(
          paymentData.creditCardReference,
          {
            driverId: userId,
          }
        );

        if (!creditCardData) {
          setDetails({
            isLoading: false,
          });
        }

        window.open(creditCardData.url, "_self");
      })();
    });

    return promise;
  };

  return (
    <GeneralCreditCardPaymentBox
      longMessage={paymentMethod.longMessage}
      shortMessage={paymentMethod.shortMessage}
      onClick={onClick}
      title={paymentMethod.title}
      {...details}
    />
  );
};

CreditCardPaymentBox.propTypes = paymentBoxPropTypes;

export default CreditCardPaymentBox;
